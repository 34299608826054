import React from 'react';
import styled from 'styled-components';
import face from '../images/face.gif';
import { Lights } from '../components/backgrounds';
import {
  SEO, Layout, RWLogo, Nav, ContactForm,
} from '../components';
import '../index.css';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={['rick', 'livernois', 'developer']} />
    <Lights />
    <Nav show page="contact" />
    <Container>
      <BigCard>
        <Img src={face} />
        My name is Rick Livernois, and I am a full stack web developer located in Memphis,
        Tennessee. I build performant web applications using modern technologies such as React
        (developed by Facebook), GraphQL (used by Pinterest), and Gatsby (used by Airbnb). My
        industry background lies in network defense and systems engineering, but I really enjoy
        building ways for people to interact on the web. Design and automation have always been
        passions of mine, and I work hard to provide the optimal solution to any business problem. I
        am available for consultation and development projects. For more information, please contact
        me below.
        <ContactForm />
      </BigCard>
    </Container>
    <RWLogo />
  </Layout>
);

export default ContactPage;

const Img = styled.img`
  border-radius: 50%;
  height: 20vmin;
  margin-right: 4vmin;
  float: left;
  @media only screen and (min-width: 800px) {
    height: 10vmin;
    margin-right: 1vmin;
  }
`;

const Container = styled.div`
  grid-area: 2 / 2 / 2 / 2;
  overflow: auto;
  @media only screen and (min-width: 800px) {
    padding: 0 10vmin;
  }
`;

const BigCard = styled.div`
  font-family: Raleway, sans-serif;
  padding: 3vmin;
  margin-top: 5vmin;
  background: rgba(229, 229, 229, 0.78);
  font-size: 5vmin;
  text-align: justify;
  @media only screen and (min-width: 800px) {
    font-size: 2.4vmin;
  }
`;
